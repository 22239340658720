<template>
  <div class="common-layout">
    <div class="header-container">
      <div style="width: 1400px; height: 145px">
        <ul class="nav-ul">
          <li
            :class="key == item.path ? 'active' : ''"
            @click="goNavPage(item)"
            v-for="item in navList"
            :key="item.id"
          >
            <div>
              <span></span>
            </div>
            <p>{{ item.name }}</p>
          </li>
        </ul>
        <div style="float: right">
          <el-dropdown>
            <div
              style="
                height: 145px;
                display: flex;
                align-items: center;
                color: #fff;
                margin: 0 20px 0px 10px;
              "
            >
              <img
                src="../assets/image/user.jpeg"
                style="width: 46px; height: 46px; margin-right: 10px; border-radius: 50%"
                alt=""
              />{{ username }}
            </div>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item>
                  <span @click="changePassword" style="margin-left: 10px">修改密码</span>
                </el-dropdown-item>
                <el-dropdown-item>
                  <span @click="goLogin" style="margin-left: 10px">退出登录</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
    </div>
    <el-container style="background: #f5f5f5; min-height: calc(100vh - 145px)">
      <router-view :key="key" />
    </el-container>

    <el-dialog title="修改密码" v-model="passDialog" width="500px">
      <el-form :model="passwordForm" label-width="90px">
        <el-form-item label="旧密码">
          <el-input v-model="passwordForm[0]" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="新密码">
          <el-input v-model="passwordForm[1]" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="确认密码">
          <el-input v-model="passwordForm[2]" placeholder=""></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="passDialog = false" type="text">取消</el-button>
        <el-button type="primary" @click="submitPass">提交</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ElMessage } from "element-plus";
import { logout, changePasswordAdmin } from "../utils/api";
export default {
  data() {
    return {
      passDialog: false,
      passwordForm: ["", "", ""],

      userType: null,
    };
  },
  computed: {
    navList() {
      let navArr = [];
      let userTypeNum = window.sessionStorage.getItem("userType");
      if (userTypeNum == 1) {
        navArr = [
          {
            path: "/equipmentList",
            name: "设备列表",
            id: 0,
          },
          {
            path: "/userManagementAdmin",
            name: "用户管理",
            id: 0,
          },
          {
            path: "/basicSetup",
            name: "基本设置",
            id: 0,
          },
        ];
      } else {
        navArr = [
          {
            path: "/equipmentList",
            name: "设备列表",
            id: 0,
          },
          {
            path: "/userManagementAdmin",
            name: "用户管理",
            id: 0,
          },
        ];
      }
      return navArr;
    },

    key() {
      return this.$route.path;
    },
    username() {
      return window.localStorage.getItem("username");
    },
  },
  methods: {
    goNavPage(item) {
      this.$router.push(item.path);
    },

    goLogin() {
      logout().then((res) => {
        if (!res.error) {
          this.$router.push("/login");
        }
      });
    },
    changePassword() {
      this.passDialog = true;
    },
    submitPass() {
      let that = this;
      if (
        this.passwordForm[0] != "" &&
        this.passwordForm[1] != "" &&
        this.passwordForm[2] != "" &&
        this.passwordForm[1] == this.passwordForm[2]
      ) {
        changePasswordAdmin({
          params: [this.passwordForm[0], this.passwordForm[1]],
        }).then((res) => {
          if (!res.error) {
            ElMessage({
              type: "success",
              message: "修改成功！",
              customClass: "mzindex",
            });
            this.passDialog = false;
            setTimeout(function () {
              that.$router.go(0);
            }, 800);
          }
        });
      } else {
        ElMessage({
          type: "error",
          message: "输入不能为空，且新密码和确认密码必须一致！",
          customClass: "mzindex",
        });
      }
    },
  },
  created() {
    this.userType = window.localStorage.getItem("userType");
  },
};
</script>

<style lang="scss" scoped>
.common-layout {
  width: 100%;
  display: inline-block;
  min-width: 1600px;
}
.header-container {
  width: 100%;
  height: 145px;
  display: block;
  background: #216cc9;

  & .nav-ul {
    float: left;
    display: flex;
    height: 145px;
    align-items: center;
    & li {
      width: 145px;
      height: 77px;
      padding-top: 8px;
      border-radius: 5px;
      text-align: center;
      float: left;
      line-height: 26px;
      cursor: pointer;
      color: #fff;
      font-size: 24px;
      & > div {
        width: 100%;
        text-align: center;

        & > span {
          width: 35px;
          height: 33px;
          display: inline-block;
          background-size: 100%;
        }
      }
    }
    & li:nth-child(1) > div > span {
      background-image: url(../assets/image/page21.png);
    }
    & li:nth-child(2) > div > span {
      background-image: url(../assets/image/page22.png);
    }
    & li:nth-child(3) > div > span {
      background-image: url(../assets/image/page23.png);
    }
  }
  & > div {
    color: #fff;
    margin: 0 auto;
  }
}
.active {
  background: #278afe;
  color: #fff !important;
}
</style>
